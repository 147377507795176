import React, {useCallback, useEffect, useState} from 'react';
import axios from "../../../api/axios";
import {ProgressSpinner} from "primereact/progressspinner";
import Spacing from "../../Spacing";
import {TabPanel, TabView} from "primereact/tabview";
import Div from "../../Div";

const styles = {
  responsiveTableContainer: {
    overflowX: 'auto',
    scrollbarColor: '#6b6b6b #2e2e2e',  // Scrollbar track and thumb colors for dark theme
    scrollbarWidth: 'thin'
  },
  topScrollBarSpacer: {
    overflowX: 'auto',
    height: '20px' // adjust based on your scrollbar height, keep minimal
  }
};

const Nfl = () => {
  const initialEntries = 10;
  const loadMoreIncrement = 5;

  const [visibleEntries, setVisibleEntries] = useState(initialEntries);
  const [statsData, setStatsData] = useState({ statsTable: [], headers: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const configs = {
    Yards: 'HotStreaks NFL',
    RushingYards: 'Rushing Yards Allowed',
    PassingYards: 'Pass Yards Allowed',
    nflProjections: 'NFL Projections',
  };
  const [currentConfigName, setCurrentConfigName] = useState(Object.keys(configs)[0]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const fetchData = useCallback(async (configKey) => {
    setIsLoading(true);
    setError('');
    try {
      const endpoint = `/home/${configs[configKey]}`;
      const response = await axios.get(endpoint);
      setStatsData({
        statsTable: response.data.data,
        headers: response.data.headers || [],
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(currentConfigName);
  }, [currentConfigName, fetchData]);

  console.log('Rendering with state:', { statsData, isLoading, error });

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const renderTableData = () => {
    const totalEntries = statsData.statsTable.length;
    const firstThirdIndex = Math.floor(totalEntries / 3);
    const secondThirdIndex = Math.floor((totalEntries * 2) / 3);

    return statsData.statsTable.map((row, rowIndex) => {
      let color = '';
      if (currentConfigName === 'RushingYards' || currentConfigName === 'PassingYards') {
        if (rowIndex < firstThirdIndex) color = 'red'; // First 33.3%
        else if (rowIndex >= firstThirdIndex && rowIndex < secondThirdIndex) color = 'yellow'; // Next 33.3%
        else color = '#31df31'; // Last 33.3%
      }

      return (
        <tr key={rowIndex}>
          <td>{rowIndex + 1}</td> {/* Serial Number Column */}
          {Object.values(row).map((value, valueIndex) => (
            <td key={valueIndex} style={{ color: color }}>{value}</td>
          ))}
        </tr>
      );
    });
  };

  const handleTabChange = (e) => {
    setActiveTabIndex(e.index);  // Set the new active tab index
    const selectedConfigKey = Object.keys(configs)[e.index];
    setCurrentConfigName(selectedConfigKey);
  };

  return (
    <>
      <Spacing lg="130" md="80" />
      <TabView className="reactTabView" activeIndex={activeTabIndex} onTabChange={handleTabChange}>
        {Object.entries(configs).map(([configKey, configDisplayName], index) => (
          <TabPanel className="reactTabPanel" key={configKey} header={configDisplayName}>
            <Div className="container statsContainer">
              <div style={styles.responsiveTableContainer}>
                <table className="statsTable">
                  <thead>
                    <tr>
                      <th style={{borderTop: "none", width: "40px", paddingLeft: "50px"}}>S.No</th>
                      {statsData.headers.map((header, index) => (
                        <th key={index} style={{borderTop: "none"}}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{renderTableData()}</tbody>
                </table>
              </div>
            </Div>
          </TabPanel>
        ))}
      </TabView>
    </>
  );
}

export default Nfl;