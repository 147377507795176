import React from 'react'
import Div from '../Div'
import { Link } from 'react-router-dom'

export default function TextWidget({ text }) {
  return (
    <Div className="cs-text_widget">
      <Link style={{ display: "flex", fontSize: "20px", fontFamily: "'Poppins'", fontWeight: "700" }} className="cs-site_branding" to="/">
        <span style={{ fontSize: "83px", marginTop: "10px", fontFamily: 'DM Sans' }} className='brandingSpan'>G</span>
        <div>
          <span className='brandingSpan'>ambling</span>
          <br />
          <span>Forecast</span>
        </div>
      </Link>
      <br />
      <p>{text}</p>
    </Div>
  )
}
