import React, { useCallback, useEffect, useState } from "react";
import axios from "../../../api/axios";
import Spacing from "../../Spacing";
import { TabPanel, TabView } from "primereact/tabview";
import Div from "../../Div";
import { ProgressSpinner } from 'primereact/progressspinner';

const styles = {
  responsiveTableContainer: {
    overflowX: 'auto',
    scrollbarColor: '#6b6b6b #2e2e2e',
    scrollbarWidth: 'thin'
  },
  topScrollBarSpacer: {
    overflowX: 'auto',
    height: '20px'
  }
};

const MLBPage = () => {
  const [statsData, setStatsData] = useState({ statsTable: [], headers: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const configs = {
    opponentstrikeout: 'Team strikeouts per game',
    opponenthit: 'Team hits per game',
    BaseballMatchupStats: 'ColdHot Matchup',
    HotStreaks_MLB: 'Hot Streaks MLB',
    innings: '\t1st Inning Runs',
    mlbProjections: 'MLB Projections',
  };
  const [currentConfigName, setCurrentConfigName] = useState(Object.keys(configs)[0]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const fetchData = useCallback(async (configKey) => {
    setIsLoading(true);
    setError('');
    try {
      const endpoint = `/home/${configs[configKey]}`;
      const response = await axios.get(endpoint);
      setStatsData({
        statsTable: response.data.data,
        headers: response.data.headers.filter(header => header !== 'Matchup Stat'),
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(currentConfigName);
  }, [currentConfigName, fetchData]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

const renderTableData = (configDisplayName, matchupType = '') => {
  let sortedData = [...statsData.statsTable];

  // Filter data based on matchupType if it's a Cold / Hot Matchup
  if (configDisplayName === 'Cold / Hot Matchup' || configDisplayName === 'ColdHot Matchup') {
      sortedData = sortedData.filter(row =>
        (matchupType === 'hot' && row['Matchup Stat'] === 'hotbatterMatch') ||
        (matchupType === 'cold' && row['Matchup Stat'] !== 'hotbatterMatch')
      ).map(row => {
        const { Pitcher, ...rest } = row;
        return rest;
      });
      console.log(sortedData);
    }
  // Sort data only for Cold / Hot Matchup
  if (configDisplayName === 'Cold / Hot Matchup' || configDisplayName === 'ColdHot Matchup') {
    sortedData.sort((a, b) => {
      const isAHot = a['Matchup Stat'] === 'hotbatterMatch';
      const isBHot = b['Matchup Stat'] === 'hotbatterMatch';
      return (isAHot === isBHot) ? 0 : isAHot ? -1 : 1;
    });
  }

  // Calculate indexes for different color sections if not Cold / Hot Matchup
  const totalRows = statsData.statsTable.length;
  const firstThirdIndex = Math.floor(totalRows * 0.333);
  const secondThirdIndex = Math.floor(totalRows * 0.666);

  return sortedData.map((row, rowIndex) => {
    let color = '#31df31'; 

    if (configDisplayName === 'Hot Streaks MLB' || configDisplayName === 'MLB Projections') {
      color = '';
    } else if (configDisplayName === 'Cold / Hot Matchup' || configDisplayName === 'ColdHot Matchup') {
      color = '';
    } else {
      // Coloring for other configurations based on data distribution
      if (rowIndex < firstThirdIndex) color = 'red';
      else if (rowIndex >= firstThirdIndex && rowIndex < secondThirdIndex) color = 'yellow';
    }

    return (
      <tr key={rowIndex}>
        <td>{rowIndex + 1}</td>
        {Object.entries(row).filter(([key]) => key !== 'Matchup Stat').map(([key, value], valueIndex) => (
          <td key={valueIndex} style={{ color }}>{value}</td>
        ))}
      </tr>
    );
  });
};



  const handleTabChange = (e) => {
    setActiveTabIndex(e.index);
    setCurrentConfigName(Object.keys(configs)[e.index]);
  };

  return (
  <>
    <Spacing lg="130" md="80" />
       <TabView className="reactTabView" activeIndex={activeTabIndex} onTabChange={handleTabChange}>
      {Object.entries(configs).map(([configKey, configDisplayName], index) => {
        const displayName = configDisplayName === 'ColdHot Matchup' ? 'Cold / Hot Matchup' : configDisplayName;
        const isColdHotMatchup = displayName === 'Cold / Hot Matchup';
    
        return (
          <TabPanel className="reactTabPanel" key={configKey} header={displayName}>
            <Div className="container statsContainer">
              <div style={styles.responsiveTableContainer}>
                {isColdHotMatchup ? (
                  <>
                    <h3 style={{ paddingLeft: "30px", paddingTop: "20px" }}>Hot Matchup</h3>
                    <table className="statsTable">
                      <thead>
                        <tr>
                          <th style={{ borderTop: "none", width: "40px", paddingLeft: "50px" }}>S.No</th>
                          {statsData.headers.filter(header => header !== 'Pitcher').map((header, index) => (
                            <th key={index} style={{ borderTop: "none" }}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>{renderTableData(displayName, 'hot')}</tbody>
                    </table>
                    <h3 style={{ paddingLeft: "30px", paddingTop: "20px" }}>Cold Matchup</h3>
                    <table className="statsTable">
                      <thead>
                        <tr>
                          <th style={{ borderTop: "none", width: "40px", paddingLeft: "50px" }}>S.No</th>
                          {statsData.headers.filter(header => header !== 'Pitcher').map((header, index) => (
                            <th key={index} style={{ borderTop: "none" }}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>{renderTableData(displayName, 'cold')}</tbody>
                    </table>
                  </>
                ) : (
                  <table className="statsTable">
                    <thead>
                      <tr>
                        <th style={{ borderTop: "none", width: "40px", paddingLeft: "50px" }}>S.No</th>
                        {statsData.headers.map((header, index) => (
                          <th key={index} style={{ borderTop: "none" }}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{renderTableData(displayName)}</tbody>
                  </table>
                )}
              </div>
            </Div>
          </TabPanel>
        );
      })}
    </TabView>
  </>
);

}

export default MLBPage;
