import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://api.gamblingforecast.com/',
    //baseURL: 'http://localhost:8001/',

});

export default axiosInstance;


