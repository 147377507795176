import React, { useCallback, useEffect, useState } from "react";
import axios from "../../../api/axios";
import Spacing from "../../Spacing";
import { TabPanel, TabView } from "primereact/tabview";
import Div from "../../Div";
import { Icon } from "@iconify/react";
import { ProgressSpinner } from 'primereact/progressspinner';

const styles = {
  responsiveTableContainer: {
    overflowX: 'auto',
    scrollbarColor: '#6b6b6b #2e2e2e',  // Scrollbar track and thumb colors for dark theme
    scrollbarWidth: 'thin'
  },
  topScrollBarSpacer: {
    overflowX: 'auto',
    height: '20px' // adjust based on your scrollbar height, keep minimal
  }
};



const NBAPage = () => {
    const initialEntries = 10;
    const [statsData, setStatsData] = useState({ statsTable: [], headers: [] });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const configs = {
        pointsPerGame: 'Opponent Points per game',
        reboundsPerGame: 'Opponent Rebounds per game',
        assistsPerGame: 'Opponent Assist per game',
        HotStreaks_NBA: 'HotStreaks NBA',
        nbaProjections: 'NBA Projections',
    };
    const [currentConfigName, setCurrentConfigName] = useState(Object.keys(configs)[0]);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const fetchData = useCallback(async (configKey) => {
        setIsLoading(true);
        setError('');
        try {
            const endpoint = `/home/${configs[configKey]}`;
            const response = await axios.get(endpoint);
            setStatsData({
                statsTable: response.data.data,
                headers: response.data.headers || [],
            });
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
      fetchData(currentConfigName);
    }, [currentConfigName, fetchData]);
  
    console.log('Rendering with state:', { statsData, isLoading, error });
  
    if (isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
        </div>
      );
    }
  
    if (error) {
      return <div>Error: {error}</div>;
    }

    const renderTableData = (configDisplayName) => {
  const totalRows = statsData.statsTable.length;
  const firstThirdIndex = Math.floor(totalRows * 0.333);
  const secondThirdIndex = Math.floor(totalRows * 0.666);

  return statsData.statsTable.map((row, rowIndex) => {
    let color;
    if (configDisplayName === 'HotStreaks NBA' || configDisplayName === 'NBA Projections') {

        color = '';
        } else {

          console.log(configDisplayName, "configDisplayName");
        if (rowIndex < firstThirdIndex) color = 'red'; // First 33.3%
        else if (rowIndex >= firstThirdIndex && rowIndex < secondThirdIndex) color = 'yellow'; // Next 33.3%
        else color = '#31df31';
    }

    return (
      <tr key={rowIndex}>
        <td>{rowIndex + 1}</td> {/* Serial Number Column */}
        {Object.values(row).map((value, valueIndex) => {
          if (valueIndex === 1 || valueIndex === 2) {
            return <td key={valueIndex} style={{ color: color }}>{value}</td>;
          } else {
            return <td key={valueIndex}>{value}</td>;
          }
        })}
      </tr>
    );
  });
};


    const handleTabChange = (e) => {
        setActiveTabIndex(e.index);  // Set the new active tab index
        const selectedConfigKey = Object.keys(configs)[e.index];
        setCurrentConfigName(selectedConfigKey);
    };


    return (
        <>
            <Spacing lg="130" md="80" />
            <TabView className="reactTabView" activeIndex={activeTabIndex} onTabChange={handleTabChange}>
                {Object.entries(configs).map(([configKey, configDisplayName], index) => (
                    <TabPanel className="reactTabPanel" key={configKey} header={configDisplayName}>
                        <Div className="container statsContainer">
                            <div style={styles.responsiveTableContainer}>

                                <table className="statsTable">
                                    <thead>
                                    <tr>
                                        <th style={{borderTop: "none", width: "40px", paddingLeft: "50px"}}>S.No</th>
                                        {/* Serial Number Header */}
                                        {statsData.headers.map((header, index) => (
                                            <th key={index} style={{borderTop: "none"}}>{header}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>{renderTableData(configDisplayName)}</tbody>
                                </table>
                            </div>
                        </Div>
                    </TabPanel>
                ))}
            </TabView>
        </>
    );
}


export default NBAPage