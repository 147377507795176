import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { TabPanel, TabView } from "primereact/tabview";
import Div from "../../Div";
import { ProgressSpinner } from 'primereact/progressspinner';
import Spacing from "../../Spacing";


const styles = {
  responsiveTableContainer: {
    overflowX: 'auto',
    scrollbarColor: '#6b6b6b #2e2e2e',  // Scrollbar track and thumb colors for dark theme
    scrollbarWidth: 'thin'
  },
  topScrollBarSpacer: {
    overflowX: 'auto',
    height: '20px' // adjust based on your scrollbar height, keep minimal
  }
};


const EV = () => {
  const [statsData, setStatsData] = useState({ statsTable: [], headers: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const fetchData = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.get('/ev/');
      console.log(response.data);
      setStatsData({
        statsTable: response.data.data,
        headers: response.data.headers || [],
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  console.log(statsData.statsTable);
  console.log(statsData.headers);
  console.log(statsData);
  useEffect(() => {
    fetchData();
  }, []);

  const renderTableData = () => {
    const totalEntries = statsData.statsTable.length;
    const oneThird = Math.ceil(totalEntries / 3);
    return statsData.statsTable.map((row, rowIndex) => {
      const rowData = Object.values(row);
      return (
        <tr key={rowIndex}>
          <td>{rowIndex + 1}</td>
          {rowData.map((value, valueIndex) => {
            let color;
            // Apply coloring only to the second-to-last and last columns
            if (valueIndex === rowData.length - 2 || valueIndex === rowData.length - 1) {
              if (rowIndex < oneThird) color = '#31df31';  // green
              else if (rowIndex >= oneThird && rowIndex < 2 * oneThird) color = 'yellow';
              else if (rowIndex >= 2 * oneThird) color = 'red';
            }
            return (
              <td key={valueIndex} style={{ color }}>{value}</td>
            );
          })}
        </tr>
      );
    });
  };
  

  const handleTabChange = (e) => {
    setActiveTabIndex(e.index);
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Spacing lg="130" md="80" />
      <TabView className="reactTabView" activeIndex={activeTabIndex} onTabChange={handleTabChange}>
        <TabPanel className="reactTabPanel" header="+EV Model">
          <Div className="container statsContainer">
            <div style={styles.responsiveTableContainer}>

             {statsData.statsTable.length > 0 ? (
                <table className="statsTable">
                  <thead>
                    <tr>
                      <th style={{ borderTop: "none", width: "40px", paddingLeft: "50px" }}>S.No</th>
                      {statsData.headers.map((header, index) => (
                        <th key={index} style={{ borderTop: "none" }}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{renderTableData()}</tbody>
                </table>
              ) : (
                 <div style={{textAlign: 'center', paddingTop: "20px"}}>No records for today.</div>)}
            </div>
          </Div>
        </TabPanel>
      </TabView>
    </>
);
}

export default EV;
