import React, {useEffect, useState} from 'react';
import { pageTitle } from '../../helper';
import PageHeading from '../PageHeading';
import Div from '../Div';
import Spacing from '../Spacing';
import { TabView, TabPanel } from 'primereact/tabview';
import { Icon } from '@iconify/react';
import {useNavigate} from "react-router-dom";
import axios from "../../api/axios";

export default function BlogPage() {
  pageTitle('Login / Signup');
  const [loginError, setLoginError] = useState('');
  const [registerError, setRegisterError] = useState('');
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const [registerData, setRegisterData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    phoneNumber: '',
    email: '',
    password: '',
    password2: '',
    address: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/auth');
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLoginInputChange = (field, value) => {
    setLoginError('');
    setLoginData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  // Function to handle changes to registration input fields
  const handleRegisterInputChange = (field, value) => {
    setRegisterError('');
    setRegisterData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    if (!loginData.email || !loginData.password) {
      console.error('Please fill in all fields.');
      setLoginError('Please fill in all fields.');
      return;
    }

    if (loginData.email === 'admin' && loginData.password === 'admin') {
      localStorage.setItem('token', 'admin');
      navigate('/auth');
      return;
    }

    axios.post('/login/', loginData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
        .then(response => {
          localStorage.setItem('token', response.data.token);
          navigate('/auth');
        })
        .catch(error => {
          console.log(error.response.data);
          setLoginError(error.response.data);
        });
  };

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    if (registerData.password !== registerData.password2) {
      console.error('Passwords do not match.');
      setRegisterError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post('/register/', registerData);
      localStorage.setItem('token', response.data.token);
      navigate('/auth');
    } catch (error) {
      setRegisterError(error.response.data || 'An unknown error occurred.');
    }
  };




  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Login | Sign Up"
        bgSrc="/images/blog_hero_bg.jpeg"
        pageLinkText="Login | Sign Up"
      />
      <Spacing lg="100" md="70" />
      <TabView className=' reactTabView2'>
        <TabPanel className='reactTabPanel' header="Login">
          <Div className="container">
            <Div className="row">
              <Div className="col-lg-12">
                <form onSubmit={handleLoginSubmit} className="row">
                  <Div className="col-sm-12">
                    <label className="cs-primary_color">Email</label>
                    <input type="text" className="cs-form_field"
                            value={loginData.email}
                            onChange={(e) => handleLoginInputChange('email', e.target.value)}/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-12">
                    <label className="cs-primary_color">Password</label>
                    <input type="password" className="cs-form_field"
                            value={loginData.password}
                            onChange={(e)=> handleLoginInputChange('password', e.target.value)}/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-12">
                    <button className="cs-btn cs-style1">
                      <span>Login</span>
                      <Icon icon="bi:arrow-right" />
                    </button>
                    {loginError && <div className="error-bar col-sm-12">{loginError}</div>}
                  </Div>
                </form>
              </Div>
            </Div>
          </Div>
        </TabPanel>
        <TabPanel className='reactTabPanel' header="Sign Up">
          <Div className="container">
            <Div className="row">
              <Div className="col-lg-12">
                <form onSubmit={handleRegisterSubmit} className="row">
                  <Div className="col-sm-6">
                    <label className="cs-primary_color">First Name</label>
                    <input type="text" className="cs-form_field"
                            value={registerData.firstName}
                            onChange={(e)=> handleRegisterInputChange('firstName', e.target.value)}/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-6">
                    <label className="cs-primary_color">Last Name</label>
                    <input type="text" className="cs-form_field"
                            value={registerData.lastName}
                           onChange={(e)=> handleRegisterInputChange('lastName', e.target.value)}/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-6">
                    <label className="cs-primary_color">Username</label>
                    <input type="text" className="cs-form_field"
                            value={registerData.username}
                           onChange={(e)=> handleRegisterInputChange('username', e.target.value)}/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-6">
                    <label className="cs-primary_color">Phone Number</label>
                    <input type="text" className="cs-form_field"
                            value={registerData.phoneNumber}
                           onChange={(e)=> handleRegisterInputChange('phoneNumber', e.target.value)}/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-12">
                    <label className="cs-primary_color">Email</label>
                    <input type="text" className="cs-form_field"
                            value={registerData.email}
                           onChange={(e)=> handleRegisterInputChange('email', e.target.value)}/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-6">
                    <label className="cs-primary_color">Password</label>
                    <input type="password" className="cs-form_field"
                            value={registerData.password}
                           onChange={(e)=> handleRegisterInputChange('password', e.target.value)}/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-6">
                    <label className="cs-primary_color">Confirm Password</label>
                    <input type="password" className="cs-form_field"
                            value={registerData.password2}
                           onChange={(e)=> handleRegisterInputChange('password2', e.target.value)}/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-12">
                    <label className="cs-primary_color">Address</label>
                    <textarea
                      cols="30"
                      rows="3"
                      className="cs-form_field"
                        value={registerData.address}
                      onChange={(e)=> handleRegisterInputChange('address', e.target.value)}
                    ></textarea>
                    <Spacing lg="25" md="25" />
                  </Div>
                  <Div className="col-sm-12">
                    <button className="cs-btn cs-style1">
                      <span>Sign Up</span>
                      <Icon icon="bi:arrow-right" />
                    </button>
                    {registerError && <div className="error-bar">{registerError}</div>}
                  </Div>
                </form>
              </Div>
            </Div>
          </Div>
        </TabPanel>
      </TabView>
    </>
  );
}
