import React from 'react'
import Card from '../../Card';
import PostSlider from '../../Slider/PostSlider';
import Div from '../../Div';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import Slider from 'react-slick';
import Post from '../../Post';

const postData = [
  {
    url: '/auth/nba',
    src: '/images/NBA.jpg',
    alt: 'Post',
    title: 'NBA',
  },
  {
    url: '/auth/mlb',
    src: '/images/MLB.jpg',
    alt: 'Post',
    title: 'MLB',
  },
  {
    url: '/auth/ev',
    src: '/images/MLB.jpg',
    alt: 'Post',
    title: 'EV',
  },
    {
    url: '/auth/nfl',
    src: '/images/MLB.jpg',
    alt: 'Post',
    title: 'NFL',
  }
];
const settings = {
  dots: false,
  arrows: false,
  infinite: true, // Consider setting this to false if you don't want looping
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 1000,
  slidesToShow: 4, // Adjusted to show only 2 slides
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,

      },
    },
  ],
};


const HomePage = () => {
  
  return (
    <>
          <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4"  style={{marginBottom: "50px"}}>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore More Statistics"
                subtitle="Statistics"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
              <Slider {...settings} className="cs-gap-24">
                {postData.map((item, index) => (
                  <Div key={index}>
                    <a href={item.url}>
                    <Post
                      url={item.url}
                      src={item.src}
                      alt={item.alt}
                      date={item.date}
                      title={item.title}
                    />
                    </a>
                  </Div>
                ))}
              </Slider>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </>
  )
}

export default HomePage