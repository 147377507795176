import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import ServicesPage from './components/Pages/ServicesPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import Layout from './components/Layout';
import FaqPage from './components/Pages/FaqPage';
import MLBPage from "./components/Pages/Stat/MLBPage";
import NBAPage from "./components/Pages/Stat/NBAPage";
import HomePage from "./components/Pages/Stat/HomePage";
import Nfl from "./components/Pages/Stat/Nfl";
import EV from "./components/Pages/Stat/EV";
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');
  return token ? <Navigate to="/auth" /> : children;
}

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="about" element={<AboutPage />} />
          <Route path="pricing" element={<ServicesPage />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="login" element={<BlogPage />} />
          <Route path="contact" element={<ContactPage />} />

          <Route path="faq" element={<FaqPage />} />
        </Route>
        <Route path="/auth" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="mlb" element={<MLBPage />} />
          <Route path="nba" element={<NBAPage />} />
          <Route path="ev" element={<EV />} />
          <Route path="nfl" element={<Nfl />} />
        </Route>


        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
